<template>
    <div class="">
        <v-divider></v-divider>
        <br>
        <v-layout row>
            <v-flex xs3>
                <div class="app-features f-converter" @click.prevent="calendarView()"
                     style="cursor: pointer">
                    <div class="feature-icon">
                        <v-icon color="white" large>calendar_today</v-icon>
                    </div>
                    <div class="feature-content">
                        <div class="content">
                            <h3>Calendar</h3>
                            <h2>{{new Date().toISOString().slice(0, 10)}}</h2>
                        </div>
                    </div>
                </div>
            </v-flex>

            <v-flex xs3>
                <div class="app-features f-collection" @click.prevent="dueCollectionView()"
                     style="cursor: pointer">
                    <div class="feature-icon">
                        <v-icon color="white" large>attach_money</v-icon>
                    </div>
                    <div class="feature-content">
                        <div class="content">
                            <h3>Collections</h3>
                            <h2>Due/Collected</h2>
                        </div>
                    </div>
                </div>
            </v-flex>
            <v-flex xs3>
                <div class="app-features f-sms">
                    <div class="feature-icon">
                        <v-icon color="white" large>sms</v-icon>
                    </div>
                    <div class="feature-content">
                        <div class="content">
                            <h3>SMS</h3>
                            <h2>0 balance</h2>
                        </div>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {}
</script>